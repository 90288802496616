var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-layout"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/logo_square.png"),"alt":""}}),_c('h1',[_vm._v("怒喵科技电商平台")]),(false)?_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              { required: true, message: '请输入手机号码' },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号码'
              }
            ]
          }
        ]),expression:"[\n          'phone',\n          {\n            rules: [\n              { required: true, message: '请输入手机号码' },\n              {\n                pattern: /^1[3456789]\\d{9}$/,\n                message: '请输入正确的手机号码'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":" 手机号","size":"large"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mobile","theme":"twoTone"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {
            rules: [
              { required: true, message: '请输入 6 位短信验证码' },
              {
                pattern: /^\d{6}$/,
                message: '请输入正确的短信验证码'
              }
            ]
          }
        ]),expression:"[\n          'code',\n          {\n            rules: [\n              { required: true, message: '请输入 6 位短信验证码' },\n              {\n                pattern: /^\\d{6}$/,\n                message: '请输入正确的短信验证码'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":" 验证码","size":"large"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock","theme":"twoTone"},slot:"prefix"}),_c('a',{staticStyle:{"padding":"5px 0"},attrs:{"slot":"suffix"},on:{"click":_vm.onSendCode},slot:"suffix"},[_vm._v(_vm._s(_vm.sendText))])],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.onPhoneSignIn}},[_vm._v(" 登录 ")])],1)],1):_vm._e(),(false)?_c('div',{staticClass:"others"},[_c('img',{staticClass:"wechat",attrs:{"src":require("../assets/images/wechat.svg"),"alt":""},on:{"click":function($event){return _vm.onOauth2SignIn('wechat')}}}),_c('img',{staticClass:"qq",attrs:{"src":require("../assets/images/qq.svg"),"alt":""},on:{"click":function($event){return _vm.onOauth2SignIn('qq')}}})]):_vm._e(),_c('a',{staticClass:"copyright",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v(" Copyright © "+_vm._s(_vm.year)+" Angry Miao 粤ICP备19155896号 "),_c('span',{staticStyle:{"float":"right","margin-right":"50px"}},[_vm._v("v2.1.0")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }