















































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, Oauth2Channel } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return {
      form: this.$form.createForm(this),
      year: new Date().getFullYear(),
      time: 0
    };
  },
  computed: {
    ...mapState("app", {
      account: "account"
    }),
    sendText(): string {
      if (this.time) {
        return this.time + "秒后重新发送";
      } else {
        return "发送短信验证码";
      }
    }
  },
  methods: {
    onSendCode() {
      this.form.validateFields(["phone"], (err, values) => {
        if (!err && !this.time) {
          dispatch.appSendSMS(values.phone).then(() => {
            if (this.time) return;
            this.$message.success("短信验证码已发送，请注意查收");
            this.time = 60;
            const timer = setInterval(() => {
              if (this.time === 1) {
                clearInterval(timer);
              }
              this.time--;
            }, 1000);
          });
        }
      });
    },
    onPhoneSignIn() {
      this.form.validateFields((err, values) => {
        if (!err) {
          dispatch
            .appPhoneSignIn(values)
            .then(this.afterSignIn)
            .catch(() => {
              this.$router.replace(this.$paths.auth);
            });
        }
      });
    },
    onOauth2SignIn(channel: Oauth2Channel) {
      dispatch.appGetOauth2Url(channel).catch(() => {
        this.$router.replace(this.$paths.auth);
      });
    },
    checkSignIn() {
      dispatch
        .appOauth2SignIn()
        .then(this.afterSignIn)
        .catch(() => {
          this.$router.replace(this.$paths.auth);
        });
    },
    afterSignIn() {
      dispatch
        .appGetAccount()
        .then(() => {
          this.$router.replace(this.$paths.products);
        })
        .catch(() => {
          this.$router.replace(this.$paths.auth);
        });
    }
  },
  mounted() {
    // this.checkSignIn();
    if (this.account.role === "admin" || this.account.role === "mall_admin") {
      this.$router.replace(this.$paths.products);
    } else if (!this.account.role) {
      window.open(
        url.auth + "?redirect=" + encodeURIComponent(window.location.href),
        "_self"
      );
    }
  }
});
